import React from "react";

export const FacebookFeedEmbedded = () => {
    return (
        <div className="fb-page"
        data-href="https://www.facebook.com/evolutiondrillklubb" 
        data-width="340"
        data-hide-cover="false"
        data-show-facepile="true"></div>
    );
};