import React, { useState } from "react";
import emailjs from "@emailjs/browser";
import { Form } from "react-bootstrap";
import "./contact.css";

export const Contact = () => {
  const [values, setValues] = useState({
    fullName: "",
    email: "",
    message: ""
  });

  const sendEmail = (e) => {
    e.preventDefault();
    emailjs.send('service_3jnygtg', 'template_xe9v807', values, 'zJPiqNcWuIJFxOKdj')
      .then(response => {
        console.log('SUCCESS!', response);
        setValues({
          fullName: '',
          email: '',
          role: '',
          message: ''
        });
      }, error => {
        console.log('FAILED...', error);
      });
  };

  const handleChange = (e) => {
    setValues(values => ({
      ...values,
      [e.target.name]: e.target.value
    }))
    console.log(values);
  }

    return (

            <div className="container-fluid div-left" align="left">
                <br />
                <h3>
                    Ønsker du vite mer om klubben, eller om drill? <br />
                    Er du nysgjerrig på å komme i kontakt med oss?
                </h3>
                <br />
                Du kan sende en mail til evodrillklubb@gmail.com, eller du kan benytte dette kontaktskjemaet:
                <br />
                <br />
        <Form>
          <Form.Group className="mb-3" controlId="modal.nameInput">
              <Form.Label>Ditt navn</Form.Label>
              <Form.Control
                type="text"
                placeholder="Ditt navn..."
                autoFocus
                value={values.fullName} 
                onChange={handleChange}
                name="fullName"
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="modal.emailInput">
              <Form.Label>Din e-post adresse</Form.Label>
              <Form.Control
                type="email"
                placeholder="Din e-post..."
                autoFocus
                value={values.email} 
                onChange={handleChange}
                name="email"
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="textInput">
              <Form.Label>Tekst</Form.Label>
              <Form.Control as="textarea" rows={5}
                            value={values.message} 
                            onChange={handleChange}
                            name="message"/>
            </Form.Group>
            </Form>
            <button className="button button3 button-black" align="right" onClick={sendEmail}>Send skjema</button>
            </div>
        
    );
};


