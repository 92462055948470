import React from "react";
import { ImageCarousel } from "../image-carousel/ImageCarousel";
import { useEffect, useState } from "react";
import "./gallery.css";

export const Gallery = () => {

    // Fetch images
    const [images, setImages] = useState();

    useEffect(() => {
      setImages(
        Array.from(Array(10).keys()).map((id) => ({
          id,
          url: `https://picsum.photos/1000?random=${id}`
        }))
      );
    }, []);

    return(
        <>
            <ImageCarousel images={images} ></ImageCarousel>
        </>
    );
};