import React from "react";
import { useEffect } from "react";

export const PageNotFound = () => {

    useEffect(() => {
        const timer = setTimeout(() => {
            <div>

            </div>
        }, 5000);
        return () => clearTimeout(timer);
      }, []);

    return (
     <>
        Denne siden er under oppbygging og vi kan oppleve at noen lenker ikke fungerer slik de skal..< br />
        Du vil bli videresent til forsiden..

     </>   
    );
};
