import React from "react";
import "./navbar.css";
import { Nav, Navbar, Form } from 'react-bootstrap';

export const NavBar = ({ member }) => {
    return (
        <Navbar className="navbar-spacing" expand="lg">
            <img src={("images/logo_rosegull_bling_1.jpg")} width="125" height="65" align="left"/>
          <Navbar.Toggle aria-controls="basic-navbar-nav"/>
          <Navbar.Collapse id="basic-navbar-nav" className="">
            <Nav className="ml-auto center">
              <Nav.Item><Nav.Link href="/">Hjem</Nav.Link></Nav.Item> 
              <Nav.Item><Nav.Link href="/gallery">Galleri</Nav.Link></Nav.Item>
              <Nav.Item><Nav.Link href="/about">Om oss</Nav.Link></Nav.Item>
              <Nav.Item><Nav.Link href="/contact">Kontakt oss</Nav.Link></Nav.Item>
              <Nav.Item><Nav.Link href="/about">Medlemssider</Nav.Link></Nav.Item>
            </Nav>
          </Navbar.Collapse>
          {member &&
            <button className="button button3 button-black" align="right">Logg inn</button>
          }
        </Navbar>
    );
};