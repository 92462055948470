import React from "react";

export const News = () => {
    return (
        <>
            <h1>
                NEWS...
            </h1>
        </>
    );
};