import React from "react";

export function About() {
    return(
        <div className="contaier-fluid">
            <h3>Om klubben:</h3>
            <br />
            Evolution Drillklubb er en ung klubb, som ble stiftet  20. februari 2020. Klubben holder
            <br />
            Vi bedriver både konkurransedrill innenfor korpsdrill, og sportsdrill. Klubben er da meldem i både Norges Musikkorpsforbund og Norges Idrettsforbund.
            <br /><br />

            I Evolution Drillklubb fokuserer vi på mestring og utvikling på hver enkelt utøver sitt nivå. Enten man er nybegynner
            eller en internasjonalt satsende utøver. Vi legger til rette for trening som fremmer utvikling over tid slik
            at utøvere kan nå de målene de setter seg.

            Evolution Drillklubb er opptatt av at vi skal være en klubb med takhøyde, også for våre satsende utøvere.
            <br />
            <br />
            <h3>Visjon:</h3>
            <br />
            
        </div>

    );
}