import React from "react";
import "./common.css";
import { NavBar } from "../navbar/NavBar";

export function Header () {
  const member=false;
    return (
      <div className="jumbotron header">
        <NavBar member={member} />
        <div>
          {/* <img src={("images/header_ed.png")} style={{width:"100%"}}/> */}
        </div>
      </div>
    );
};
