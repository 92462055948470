import React from "react";
import {Routes, Route} from "react-router-dom";
import { About } from "../about/About";
import { Main } from "../main/Main";
import { PageNotFound } from "./PageNotFound";
import { Footer } from "./Footer";
import { Header } from "./Header";
import "./common.css";
import { Gallery } from "../gallery/Gallery";
import { Contact } from "../contact/Contact";
import { Container } from "react-bootstrap";
import { News } from "../news/News";
import { SideSection } from "../SideSection/SideSection";

export function App() {
  return (
    <>
      <div className="container-fluid">
      <Container>
        <Header></Header>

          <Routes>
              <Route path="/" element={<About />} />
              <Route path="/about" element = {<About />} />
              <Route path="/contact" element = {<Contact />} />
              <Route path="/gallery" element= {<Gallery />} /> 
              <Route path="/news" element= {<News />} />
              <Route path="*" element = {<PageNotFound/>} />
          </Routes>

          <Footer></Footer>
        </Container>
      </div> 
      </>
  );
};