import React from "react";
import "./common.css";
import { FaFacebookSquare, FaInstagramSquare } from 'react-icons/fa';

export const Footer = () => {
    return (
        <footer className="container fixed-bottom">
            <div className="copyright text-center">
                <a href="https://www.facebook.com/evolutiondrillklubb/" target="_blank">
                    <FaFacebookSquare size={28} color="#333"/>          
                </a> 

                <a href="https://www.instagram.com/evolutiondrillklubb/" target="_blank">
                    <FaInstagramSquare size={28} color="#333"/>          
                </a>
                {"   "}
                 &copy; 2022 Evolution Drillklubb
            </div>           
        </footer>
    );
};